/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RateUpdateRequest
 */
export interface RateUpdateRequest {
    /**
     * The ID of the hotel stay this rebooking request belongs to.
     * @type {string}
     * @memberof RateUpdateRequest
     */
    hotelStayId: string;
    /**
     * The ID of the payment this rebooking request belongs to.
     * @type {string}
     * @memberof RateUpdateRequest
     */
    paymentId: string;
}

/**
 * Check if a given object implements the RateUpdateRequest interface.
 */
export function instanceOfRateUpdateRequest(value: object): value is RateUpdateRequest {
    if (!('hotelStayId' in value) || value['hotelStayId'] === undefined) return false;
    if (!('paymentId' in value) || value['paymentId'] === undefined) return false;
    return true;
}

export function RateUpdateRequestFromJSON(json: any): RateUpdateRequest {
    return RateUpdateRequestFromJSONTyped(json, false);
}

export function RateUpdateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): RateUpdateRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'hotelStayId': json['hotel_stay_id'],
        'paymentId': json['payment_id'],
    };
}

export function RateUpdateRequestToJSON(value?: RateUpdateRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'hotel_stay_id': value['hotelStayId'],
        'payment_id': value['paymentId'],
    };
}

