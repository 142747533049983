/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const RoomQualifier = {
    Standard: 'Standard',
    Deluxe: 'Deluxe',
    Presidential: 'Presidential',
    Executive: 'Executive',
    Penthouse: 'Penthouse',
    Bridal: 'Bridal',
    Honeymoon: 'Honeymoon',
    Junior: 'Junior',
    Mini: 'Mini',
    Premium: 'Premium',
    Premier: 'Premier',
    Superior: 'Superior'
} as const;
export type RoomQualifier = typeof RoomQualifier[keyof typeof RoomQualifier];


export function instanceOfRoomQualifier(value: any): boolean {
    for (const key in RoomQualifier) {
        if (Object.prototype.hasOwnProperty.call(RoomQualifier, key)) {
            if ((RoomQualifier as Record<string, RoomQualifier>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function RoomQualifierFromJSON(json: any): RoomQualifier {
    return RoomQualifierFromJSONTyped(json, false);
}

export function RoomQualifierFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoomQualifier {
    return json as RoomQualifier;
}

export function RoomQualifierToJSON(value?: RoomQualifier | null): any {
    return value as any;
}
