/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RoomQualifier } from './RoomQualifier';
import {
    RoomQualifierFromJSON,
    RoomQualifierFromJSONTyped,
    RoomQualifierToJSON,
} from './RoomQualifier';
import type { RoomView } from './RoomView';
import {
    RoomViewFromJSON,
    RoomViewFromJSONTyped,
    RoomViewToJSON,
} from './RoomView';
import type { RoomLayoutType } from './RoomLayoutType';
import {
    RoomLayoutTypeFromJSON,
    RoomLayoutTypeFromJSONTyped,
    RoomLayoutTypeToJSON,
} from './RoomLayoutType';

/**
 * Room information
 * @export
 * @interface RoomInfo
 */
export interface RoomInfo {
    /**
     * 
     * @type {string}
     * @memberof RoomInfo
     */
    roomName?: string;
    /**
     * 
     * @type {RoomLayoutType}
     * @memberof RoomInfo
     */
    roomLayoutType?: RoomLayoutType;
    /**
     * 
     * @type {Array<RoomQualifier>}
     * @memberof RoomInfo
     */
    roomQualifiers?: Array<RoomQualifier>;
    /**
     * 
     * @type {number}
     * @memberof RoomInfo
     */
    bedroomCount?: number;
    /**
     * 
     * @type {Array<RoomView>}
     * @memberof RoomInfo
     */
    views?: Array<RoomView>;
    /**
     * 
     * @type {number}
     * @memberof RoomInfo
     */
    numberOfSingleBeds?: number;
    /**
     * 
     * @type {number}
     * @memberof RoomInfo
     */
    numberOfTwinBeds?: number;
    /**
     * 
     * @type {number}
     * @memberof RoomInfo
     */
    numberOfFullBeds?: number;
    /**
     * 
     * @type {number}
     * @memberof RoomInfo
     */
    numberOfQueenBeds?: number;
    /**
     * 
     * @type {number}
     * @memberof RoomInfo
     */
    numberOfKingBeds?: number;
    /**
     * 
     * @type {number}
     * @memberof RoomInfo
     */
    areaSqMeter?: number;
    /**
     * 
     * @type {number}
     * @memberof RoomInfo
     */
    areaSqFt?: number;
    /**
     * 
     * @type {boolean}
     * @memberof RoomInfo
     */
    accessible?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RoomInfo
     */
    smoking?: boolean;
}

/**
 * Check if a given object implements the RoomInfo interface.
 */
export function instanceOfRoomInfo(value: object): value is RoomInfo {
    return true;
}

export function RoomInfoFromJSON(json: any): RoomInfo {
    return RoomInfoFromJSONTyped(json, false);
}

export function RoomInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoomInfo {
    if (json == null) {
        return json;
    }
    return {
        
        'roomName': json['room_name'] == null ? undefined : json['room_name'],
        'roomLayoutType': json['room_layout_type'] == null ? undefined : RoomLayoutTypeFromJSON(json['room_layout_type']),
        'roomQualifiers': json['room_qualifiers'] == null ? undefined : ((json['room_qualifiers'] as Array<any>).map(RoomQualifierFromJSON)),
        'bedroomCount': json['bedroom_count'] == null ? undefined : json['bedroom_count'],
        'views': json['views'] == null ? undefined : ((json['views'] as Array<any>).map(RoomViewFromJSON)),
        'numberOfSingleBeds': json['number_of_single_beds'] == null ? undefined : json['number_of_single_beds'],
        'numberOfTwinBeds': json['number_of_twin_beds'] == null ? undefined : json['number_of_twin_beds'],
        'numberOfFullBeds': json['number_of_full_beds'] == null ? undefined : json['number_of_full_beds'],
        'numberOfQueenBeds': json['number_of_queen_beds'] == null ? undefined : json['number_of_queen_beds'],
        'numberOfKingBeds': json['number_of_king_beds'] == null ? undefined : json['number_of_king_beds'],
        'areaSqMeter': json['area_sq_meter'] == null ? undefined : json['area_sq_meter'],
        'areaSqFt': json['area_sq_ft'] == null ? undefined : json['area_sq_ft'],
        'accessible': json['accessible'] == null ? undefined : json['accessible'],
        'smoking': json['smoking'] == null ? undefined : json['smoking'],
    };
}

export function RoomInfoToJSON(value?: RoomInfo | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'room_name': value['roomName'],
        'room_layout_type': RoomLayoutTypeToJSON(value['roomLayoutType']),
        'room_qualifiers': value['roomQualifiers'] == null ? undefined : ((value['roomQualifiers'] as Array<any>).map(RoomQualifierToJSON)),
        'bedroom_count': value['bedroomCount'],
        'views': value['views'] == null ? undefined : ((value['views'] as Array<any>).map(RoomViewToJSON)),
        'number_of_single_beds': value['numberOfSingleBeds'],
        'number_of_twin_beds': value['numberOfTwinBeds'],
        'number_of_full_beds': value['numberOfFullBeds'],
        'number_of_queen_beds': value['numberOfQueenBeds'],
        'number_of_king_beds': value['numberOfKingBeds'],
        'area_sq_meter': value['areaSqMeter'],
        'area_sq_ft': value['areaSqFt'],
        'accessible': value['accessible'],
        'smoking': value['smoking'],
    };
}

