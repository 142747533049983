/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PointsAmount } from './PointsAmount';
import {
    PointsAmountFromJSON,
    PointsAmountFromJSONTyped,
    PointsAmountToJSON,
} from './PointsAmount';
import type { CurrentRateMatch } from './CurrentRateMatch';
import {
    CurrentRateMatchFromJSON,
    CurrentRateMatchFromJSONTyped,
    CurrentRateMatchToJSON,
} from './CurrentRateMatch';
import type { BookingDetails } from './BookingDetails';
import {
    BookingDetailsFromJSON,
    BookingDetailsFromJSONTyped,
    BookingDetailsToJSON,
} from './BookingDetails';
import type { CurrencyAmount } from './CurrencyAmount';
import {
    CurrencyAmountFromJSON,
    CurrencyAmountFromJSONTyped,
    CurrencyAmountToJSON,
} from './CurrencyAmount';

/**
 * 
 * @export
 * @interface Hotel
 */
export interface Hotel {
    /**
     * 
     * @type {string}
     * @memberof Hotel
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Hotel
     */
    emailId?: string;
    /**
     * 
     * @type {number}
     * @memberof Hotel
     */
    vervotechPropertyId?: number;
    /**
     * 
     * @type {string}
     * @memberof Hotel
     */
    name?: string;
    /**
     * 
     * @type {Date}
     * @memberof Hotel
     */
    checkInDatetime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Hotel
     */
    checkOutDatetime?: Date;
    /**
     * 
     * @type {string}
     * @memberof Hotel
     */
    confirmationNumber?: string;
    /**
     * Gondola-internal booking ID. For user-facing Gondola confirmation number, use gondola_booking_id instead.
     * @type {number}
     * @memberof Hotel
     */
    bookingId?: number;
    /**
     * 
     * @type {string}
     * @memberof Hotel
     */
    guestFirstName?: string;
    /**
     * 
     * @type {string}
     * @memberof Hotel
     */
    guestLastName?: string;
    /**
     * User-facing Gondola booking confirmation number.
     * @type {string}
     * @memberof Hotel
     */
    gondolaBookingId?: string;
    /**
     * 
     * @type {string}
     * @memberof Hotel
     */
    roomType?: string;
    /**
     * 
     * @type {string}
     * @memberof Hotel
     */
    rateName?: string;
    /**
     * 
     * @type {number}
     * @memberof Hotel
     */
    roomCount?: number;
    /**
     * 
     * @type {string}
     * @memberof Hotel
     */
    guests?: string;
    /**
     * 
     * @type {number}
     * @memberof Hotel
     */
    totalCost?: number;
    /**
     * 
     * @type {string}
     * @memberof Hotel
     */
    currency?: string;
    /**
     * The currency of the rate the user booked with (e.g. amex vs marriott)
     * @type {string}
     * @memberof Hotel
     */
    pointsRateCurrency?: string;
    /**
     * 
     * @type {CurrencyAmount}
     * @memberof Hotel
     */
    currentPrice?: CurrencyAmount;
    /**
     * 
     * @type {Date}
     * @memberof Hotel
     */
    currentPriceUpdatedAt?: Date;
    /**
     * 
     * @type {CurrentRateMatch}
     * @memberof Hotel
     */
    currentRateMatch?: CurrentRateMatch;
    /**
     * The points price of the hotel currency (e.g. Marriott points).
     * @type {number}
     * @memberof Hotel
     */
    currentPointsPrice?: number;
    /**
     * 
     * @type {Date}
     * @memberof Hotel
     */
    currentPointsPriceUpdatedAt?: Date;
    /**
     * 
     * @type {CurrentRateMatch}
     * @memberof Hotel
     */
    currentPointsRateMatch?: CurrentRateMatch;
    /**
     * The ratio of points to the booking currency (e.g. 10:1)
     * @type {number}
     * @memberof Hotel
     */
    transferRatio?: number;
    /**
     * 
     * @type {Date}
     * @memberof Hotel
     */
    cancellableUntil?: Date;
    /**
     * 
     * @type {number}
     * @memberof Hotel
     */
    totalPointsRedeemed?: number;
    /**
     * The equivalent total points redeemed in the hotel currency (e.g. Marriott points)
     * @type {number}
     * @memberof Hotel
     */
    totalPointsRedeemedCurrencyEquivalent?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Hotel
     */
    cancelled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Hotel
     */
    refundable?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof Hotel
     */
    reservationUpdatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof Hotel
     */
    reservationCancellationPolicy?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Hotel
     */
    reservationUpdateInProgress?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Hotel
     */
    hotelSupplier?: string;
    /**
     * 
     * @type {string}
     * @memberof Hotel
     */
    bookingAgent?: string;
    /**
     * 
     * @type {Date}
     * @memberof Hotel
     */
    bookingDate?: Date;
    /**
     * The loyalty program of the hotel
     * @type {string}
     * @memberof Hotel
     */
    loyaltyProgram?: string;
    /**
     * 
     * @type {BookingDetails}
     * @memberof Hotel
     */
    bookingDetails?: BookingDetails;
    /**
     * 
     * @type {string}
     * @memberof Hotel
     */
    tripId?: string;
    /**
     * 
     * @type {string}
     * @memberof Hotel
     */
    scheduledEventCode?: string;
    /**
     * The number of loyalty program status nights accrued
     * @type {number}
     * @memberof Hotel
     */
    statusNights?: number;
    /**
     * 
     * @type {PointsAmount}
     * @memberof Hotel
     */
    loyaltyPointsEarned?: PointsAmount;
    /**
     * The last four digits of the card used to book the hotel
     * @type {string}
     * @memberof Hotel
     */
    cardLastFour?: string;
    /**
     * 
     * @type {PointsAmount}
     * @memberof Hotel
     */
    creditCardEarnings?: PointsAmount;
    /**
     * 
     * @type {CurrencyAmount}
     * @memberof Hotel
     */
    gondolaCash?: CurrencyAmount;
    /**
     * 
     * @type {CurrencyAmount}
     * @memberof Hotel
     */
    totalCashEquivalentEarnings?: CurrencyAmount;
    /**
     * The percentage of the total earnings that are cash equivalents
     * @type {number}
     * @memberof Hotel
     */
    totalEarningsPercentage?: number;
    /**
     * 
     * @type {string}
     * @memberof Hotel
     */
    rateUpdateStatus?: HotelRateUpdateStatusEnum;
}


/**
 * @export
 */
export const HotelRateUpdateStatusEnum = {
    Pending: 'PENDING',
    Completed: 'COMPLETED',
    Failed: 'FAILED'
} as const;
export type HotelRateUpdateStatusEnum = typeof HotelRateUpdateStatusEnum[keyof typeof HotelRateUpdateStatusEnum];


/**
 * Check if a given object implements the Hotel interface.
 */
export function instanceOfHotel(value: object): value is Hotel {
    return true;
}

export function HotelFromJSON(json: any): Hotel {
    return HotelFromJSONTyped(json, false);
}

export function HotelFromJSONTyped(json: any, ignoreDiscriminator: boolean): Hotel {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'emailId': json['email_id'] == null ? undefined : json['email_id'],
        'vervotechPropertyId': json['vervotech_property_id'] == null ? undefined : json['vervotech_property_id'],
        'name': json['name'] == null ? undefined : json['name'],
        'checkInDatetime': json['check_in_datetime'] == null ? undefined : (new Date(json['check_in_datetime'])),
        'checkOutDatetime': json['check_out_datetime'] == null ? undefined : (new Date(json['check_out_datetime'])),
        'confirmationNumber': json['confirmation_number'] == null ? undefined : json['confirmation_number'],
        'bookingId': json['booking_id'] == null ? undefined : json['booking_id'],
        'guestFirstName': json['guest_first_name'] == null ? undefined : json['guest_first_name'],
        'guestLastName': json['guest_last_name'] == null ? undefined : json['guest_last_name'],
        'gondolaBookingId': json['gondola_booking_id'] == null ? undefined : json['gondola_booking_id'],
        'roomType': json['room_type'] == null ? undefined : json['room_type'],
        'rateName': json['rate_name'] == null ? undefined : json['rate_name'],
        'roomCount': json['room_count'] == null ? undefined : json['room_count'],
        'guests': json['guests'] == null ? undefined : json['guests'],
        'totalCost': json['total_cost'] == null ? undefined : json['total_cost'],
        'currency': json['currency'] == null ? undefined : json['currency'],
        'pointsRateCurrency': json['points_rate_currency'] == null ? undefined : json['points_rate_currency'],
        'currentPrice': json['current_price'] == null ? undefined : CurrencyAmountFromJSON(json['current_price']),
        'currentPriceUpdatedAt': json['current_price_updated_at'] == null ? undefined : (new Date(json['current_price_updated_at'])),
        'currentRateMatch': json['current_rate_match'] == null ? undefined : CurrentRateMatchFromJSON(json['current_rate_match']),
        'currentPointsPrice': json['current_points_price'] == null ? undefined : json['current_points_price'],
        'currentPointsPriceUpdatedAt': json['current_points_price_updated_at'] == null ? undefined : (new Date(json['current_points_price_updated_at'])),
        'currentPointsRateMatch': json['current_points_rate_match'] == null ? undefined : CurrentRateMatchFromJSON(json['current_points_rate_match']),
        'transferRatio': json['transfer_ratio'] == null ? undefined : json['transfer_ratio'],
        'cancellableUntil': json['cancellable_until'] == null ? undefined : (new Date(json['cancellable_until'])),
        'totalPointsRedeemed': json['total_points_redeemed'] == null ? undefined : json['total_points_redeemed'],
        'totalPointsRedeemedCurrencyEquivalent': json['total_points_redeemed_currency_equivalent'] == null ? undefined : json['total_points_redeemed_currency_equivalent'],
        'cancelled': json['cancelled'] == null ? undefined : json['cancelled'],
        'refundable': json['refundable'] == null ? undefined : json['refundable'],
        'reservationUpdatedAt': json['reservation_updated_at'] == null ? undefined : (new Date(json['reservation_updated_at'])),
        'reservationCancellationPolicy': json['reservation_cancellation_policy'] == null ? undefined : json['reservation_cancellation_policy'],
        'reservationUpdateInProgress': json['reservation_update_in_progress'] == null ? undefined : json['reservation_update_in_progress'],
        'hotelSupplier': json['hotel_supplier'] == null ? undefined : json['hotel_supplier'],
        'bookingAgent': json['booking_agent'] == null ? undefined : json['booking_agent'],
        'bookingDate': json['booking_date'] == null ? undefined : (new Date(json['booking_date'])),
        'loyaltyProgram': json['loyalty_program'] == null ? undefined : json['loyalty_program'],
        'bookingDetails': json['booking_details'] == null ? undefined : BookingDetailsFromJSON(json['booking_details']),
        'tripId': json['trip_id'] == null ? undefined : json['trip_id'],
        'scheduledEventCode': json['scheduled_event_code'] == null ? undefined : json['scheduled_event_code'],
        'statusNights': json['status_nights'] == null ? undefined : json['status_nights'],
        'loyaltyPointsEarned': json['loyalty_points_earned'] == null ? undefined : PointsAmountFromJSON(json['loyalty_points_earned']),
        'cardLastFour': json['card_last_four'] == null ? undefined : json['card_last_four'],
        'creditCardEarnings': json['credit_card_earnings'] == null ? undefined : PointsAmountFromJSON(json['credit_card_earnings']),
        'gondolaCash': json['gondola_cash'] == null ? undefined : CurrencyAmountFromJSON(json['gondola_cash']),
        'totalCashEquivalentEarnings': json['total_cash_equivalent_earnings'] == null ? undefined : CurrencyAmountFromJSON(json['total_cash_equivalent_earnings']),
        'totalEarningsPercentage': json['total_earnings_percentage'] == null ? undefined : json['total_earnings_percentage'],
        'rateUpdateStatus': json['rate_update_status'] == null ? undefined : json['rate_update_status'],
    };
}

export function HotelToJSON(value?: Hotel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'email_id': value['emailId'],
        'vervotech_property_id': value['vervotechPropertyId'],
        'name': value['name'],
        'check_in_datetime': value['checkInDatetime'] == null ? undefined : ((value['checkInDatetime']).toISOString()),
        'check_out_datetime': value['checkOutDatetime'] == null ? undefined : ((value['checkOutDatetime']).toISOString()),
        'confirmation_number': value['confirmationNumber'],
        'booking_id': value['bookingId'],
        'guest_first_name': value['guestFirstName'],
        'guest_last_name': value['guestLastName'],
        'gondola_booking_id': value['gondolaBookingId'],
        'room_type': value['roomType'],
        'rate_name': value['rateName'],
        'room_count': value['roomCount'],
        'guests': value['guests'],
        'total_cost': value['totalCost'],
        'currency': value['currency'],
        'points_rate_currency': value['pointsRateCurrency'],
        'current_price': CurrencyAmountToJSON(value['currentPrice']),
        'current_price_updated_at': value['currentPriceUpdatedAt'] == null ? undefined : ((value['currentPriceUpdatedAt']).toISOString()),
        'current_rate_match': CurrentRateMatchToJSON(value['currentRateMatch']),
        'current_points_price': value['currentPointsPrice'],
        'current_points_price_updated_at': value['currentPointsPriceUpdatedAt'] == null ? undefined : ((value['currentPointsPriceUpdatedAt']).toISOString()),
        'current_points_rate_match': CurrentRateMatchToJSON(value['currentPointsRateMatch']),
        'transfer_ratio': value['transferRatio'],
        'cancellable_until': value['cancellableUntil'] == null ? undefined : ((value['cancellableUntil']).toISOString()),
        'total_points_redeemed': value['totalPointsRedeemed'],
        'total_points_redeemed_currency_equivalent': value['totalPointsRedeemedCurrencyEquivalent'],
        'cancelled': value['cancelled'],
        'refundable': value['refundable'],
        'reservation_updated_at': value['reservationUpdatedAt'] == null ? undefined : ((value['reservationUpdatedAt']).toISOString()),
        'reservation_cancellation_policy': value['reservationCancellationPolicy'],
        'reservation_update_in_progress': value['reservationUpdateInProgress'],
        'hotel_supplier': value['hotelSupplier'],
        'booking_agent': value['bookingAgent'],
        'booking_date': value['bookingDate'] == null ? undefined : ((value['bookingDate']).toISOString()),
        'loyalty_program': value['loyaltyProgram'],
        'booking_details': BookingDetailsToJSON(value['bookingDetails']),
        'trip_id': value['tripId'],
        'scheduled_event_code': value['scheduledEventCode'],
        'status_nights': value['statusNights'],
        'loyalty_points_earned': PointsAmountToJSON(value['loyaltyPointsEarned']),
        'card_last_four': value['cardLastFour'],
        'credit_card_earnings': PointsAmountToJSON(value['creditCardEarnings']),
        'gondola_cash': CurrencyAmountToJSON(value['gondolaCash']),
        'total_cash_equivalent_earnings': CurrencyAmountToJSON(value['totalCashEquivalentEarnings']),
        'total_earnings_percentage': value['totalEarningsPercentage'],
        'rate_update_status': value['rateUpdateStatus'],
    };
}

