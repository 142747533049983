/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Account,
  AdjustCreditError,
  AdjustCreditRequest,
  ApplyBookingCreditsRequest,
  CanSendEmailRequest,
  CanSendEmailResponse,
  CreateUpdateLoyaltyAccountRequest,
  Email,
  GondolaMappedResponse,
  HTTPBadRequestError,
  HTTPUnauthorizedError,
  HTTPValidationError,
  Hotel,
  Itineraries,
  LoyaltyAccount,
  LoyaltyAccountsResponse,
  ManageGmailRequest,
  ManageGmailResponse,
  MemberStayRequest,
  MemberStayResponse,
  MxConnectWidgetUrlRequest,
  MxConnectWidgetUrlResponse,
  MxReward,
  MxRewardUpdateRequest,
  MxRewards,
  MxSyncRequest,
  NotFoundError,
  PaymentMethod,
  PaymentMethodPutRequest,
  RatePastStayRequest,
  RateUpdateRequest,
  RateUpdateResponse,
  RecordEmailSentRequest,
  Referral,
  ReferralsRequest,
  ServerError,
  SubscriptionRequest,
  SuccessResponse,
  TravelHistoryResponse,
  TravelProfile,
  TravelProfileResponse,
  Trip,
  Trips,
  User,
  UserBookingCredits,
  UserCredits,
  UserPostRequest,
  UserPutRequest,
  WelcomeActions,
} from '../models/index';
import {
    AccountFromJSON,
    AccountToJSON,
    AdjustCreditErrorFromJSON,
    AdjustCreditErrorToJSON,
    AdjustCreditRequestFromJSON,
    AdjustCreditRequestToJSON,
    ApplyBookingCreditsRequestFromJSON,
    ApplyBookingCreditsRequestToJSON,
    CanSendEmailRequestFromJSON,
    CanSendEmailRequestToJSON,
    CanSendEmailResponseFromJSON,
    CanSendEmailResponseToJSON,
    CreateUpdateLoyaltyAccountRequestFromJSON,
    CreateUpdateLoyaltyAccountRequestToJSON,
    EmailFromJSON,
    EmailToJSON,
    GondolaMappedResponseFromJSON,
    GondolaMappedResponseToJSON,
    HTTPBadRequestErrorFromJSON,
    HTTPBadRequestErrorToJSON,
    HTTPUnauthorizedErrorFromJSON,
    HTTPUnauthorizedErrorToJSON,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    HotelFromJSON,
    HotelToJSON,
    ItinerariesFromJSON,
    ItinerariesToJSON,
    LoyaltyAccountFromJSON,
    LoyaltyAccountToJSON,
    LoyaltyAccountsResponseFromJSON,
    LoyaltyAccountsResponseToJSON,
    ManageGmailRequestFromJSON,
    ManageGmailRequestToJSON,
    ManageGmailResponseFromJSON,
    ManageGmailResponseToJSON,
    MemberStayRequestFromJSON,
    MemberStayRequestToJSON,
    MemberStayResponseFromJSON,
    MemberStayResponseToJSON,
    MxConnectWidgetUrlRequestFromJSON,
    MxConnectWidgetUrlRequestToJSON,
    MxConnectWidgetUrlResponseFromJSON,
    MxConnectWidgetUrlResponseToJSON,
    MxRewardFromJSON,
    MxRewardToJSON,
    MxRewardUpdateRequestFromJSON,
    MxRewardUpdateRequestToJSON,
    MxRewardsFromJSON,
    MxRewardsToJSON,
    MxSyncRequestFromJSON,
    MxSyncRequestToJSON,
    NotFoundErrorFromJSON,
    NotFoundErrorToJSON,
    PaymentMethodFromJSON,
    PaymentMethodToJSON,
    PaymentMethodPutRequestFromJSON,
    PaymentMethodPutRequestToJSON,
    RatePastStayRequestFromJSON,
    RatePastStayRequestToJSON,
    RateUpdateRequestFromJSON,
    RateUpdateRequestToJSON,
    RateUpdateResponseFromJSON,
    RateUpdateResponseToJSON,
    RecordEmailSentRequestFromJSON,
    RecordEmailSentRequestToJSON,
    ReferralFromJSON,
    ReferralToJSON,
    ReferralsRequestFromJSON,
    ReferralsRequestToJSON,
    ServerErrorFromJSON,
    ServerErrorToJSON,
    SubscriptionRequestFromJSON,
    SubscriptionRequestToJSON,
    SuccessResponseFromJSON,
    SuccessResponseToJSON,
    TravelHistoryResponseFromJSON,
    TravelHistoryResponseToJSON,
    TravelProfileFromJSON,
    TravelProfileToJSON,
    TravelProfileResponseFromJSON,
    TravelProfileResponseToJSON,
    TripFromJSON,
    TripToJSON,
    TripsFromJSON,
    TripsToJSON,
    UserFromJSON,
    UserToJSON,
    UserBookingCreditsFromJSON,
    UserBookingCreditsToJSON,
    UserCreditsFromJSON,
    UserCreditsToJSON,
    UserPostRequestFromJSON,
    UserPostRequestToJSON,
    UserPutRequestFromJSON,
    UserPutRequestToJSON,
    WelcomeActionsFromJSON,
    WelcomeActionsToJSON,
} from '../models/index';

export interface MemberStaysPostRequest {
    memberStayRequest: MemberStayRequest;
}

export interface PaymentMethodPutOperationRequest {
    paymentMethodId: string;
    paymentMethodPutRequest: PaymentMethodPutRequest;
}

export interface RateUpdateGetRequest {
    hotelStayId: string;
}

export interface RateUpdatePostRequest {
    rateUpdateRequest: RateUpdateRequest;
}

export interface UserLoyaltyAccountsGetRequest {
    extended?: boolean;
}

export interface UserManageGmailAccountPostRequest {
    manageGmailRequest: ManageGmailRequest;
}

export interface UserPaymentMethodsPaymentMethodIdDeleteRequest {
    paymentMethodId: string;
}

export interface UserPaymentMethodsPaymentMethodIdGetRequest {
    paymentMethodId: string;
}

export interface UserPaymentMethodsPostRequest {
    paymentMethod: PaymentMethod;
}

export interface UserPostOperationRequest {
    userPostRequest?: UserPostRequest;
}

export interface UserPutOperationRequest {
    userPutRequest?: UserPutRequest;
}

export interface UserTravelProfilesPostRequest {
    travelProfile: TravelProfile;
}

export interface UserTravelProfilesTravelProfileIdGetRequest {
    travelProfileId: string;
}

export interface UserTravelProfilesTravelProfileIdPutRequest {
    travelProfileId: string;
    travelProfile: TravelProfile;
}

export interface UserTripsHotelsBookingsBookingIdPutRequest {
    bookingId: string;
}

export interface UserTripsPostRequest {
    trip: Trip;
}

export interface UsersCreditsBookingsBookingIdPostRequest {
    bookingId: number;
    applyBookingCreditsRequest: ApplyBookingCreditsRequest;
}

export interface UsersCreditsBookingsBookingIdPutRequest {
    bookingId: number;
    adjustCreditRequest: AdjustCreditRequest;
}

export interface UsersEmailsCanSendPostRequest {
    canSendEmailRequest: CanSendEmailRequest;
}

export interface UsersEmailsPostRequest {
    recordEmailSentRequest: RecordEmailSentRequest;
}

export interface UsersGondolaMappedYearYearGetRequest {
    year: number;
}

export interface UsersInvitesPostRequest {
    referralsRequest: ReferralsRequest;
}

export interface UsersMxConnectWidgetUrlsPostRequest {
    mxConnectWidgetUrlRequest: MxConnectWidgetUrlRequest;
}

export interface UsersMxRewardsMxRewardIdDeleteRequest {
    mxRewardId: number;
}

export interface UsersMxRewardsMxRewardIdPutRequest {
    mxRewardId: string;
    mxRewardUpdateRequest: MxRewardUpdateRequest;
}

export interface UsersMxRewardsRewardsProgramsNameGetRequest {
    name: string;
}

export interface UsersMxSyncPostRequest {
    mxSyncRequest: MxSyncRequest;
}

export interface UsersSubscriptionsPutRequest {
    subscriptionRequest: SubscriptionRequest;
}

export interface UsersUserIdAccountsAccountIdDeleteRequest {
    userId: string;
    accountId: string;
}

export interface UsersUserIdBookingsBookingIdRatePastStayPutRequest {
    userId: string;
    bookingId: number;
    ratePastStayRequest: RatePastStayRequest;
}

export interface UsersUserIdWelcomeActionsGetRequest {
    userId: string;
}

export interface UsersUserIdWelcomeActionsWelcomeActionIdDeleteRequest {
    userId: string;
    welcomeActionId: number;
}

export interface V2UserLoyaltyAccountsGetRequest {
    extended?: boolean;
}

export interface V2UserLoyaltyAccountsLoyaltyAccountIdPutRequest {
    loyaltyAccountId: string;
    createUpdateLoyaltyAccountRequest: CreateUpdateLoyaltyAccountRequest;
}

export interface V2UserLoyaltyAccountsPostRequest {
    createUpdateLoyaltyAccountRequest: CreateUpdateLoyaltyAccountRequest;
}

/**
 * 
 */
export class UserApi extends runtime.BaseAPI {

    /**
     * Get member stays.
     */
    async memberStaysPostRaw(requestParameters: MemberStaysPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MemberStayResponse>> {
        if (requestParameters['memberStayRequest'] == null) {
            throw new runtime.RequiredError(
                'memberStayRequest',
                'Required parameter "memberStayRequest" was null or undefined when calling memberStaysPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/member_stays`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MemberStayRequestToJSON(requestParameters['memberStayRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MemberStayResponseFromJSON(jsonValue));
    }

    /**
     * Get member stays.
     */
    async memberStaysPost(requestParameters: MemberStaysPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MemberStayResponse> {
        const response = await this.memberStaysPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update payment method values
     */
    async paymentMethodPutRaw(requestParameters: PaymentMethodPutOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaymentMethod>> {
        if (requestParameters['paymentMethodId'] == null) {
            throw new runtime.RequiredError(
                'paymentMethodId',
                'Required parameter "paymentMethodId" was null or undefined when calling paymentMethodPut().'
            );
        }

        if (requestParameters['paymentMethodPutRequest'] == null) {
            throw new runtime.RequiredError(
                'paymentMethodPutRequest',
                'Required parameter "paymentMethodPutRequest" was null or undefined when calling paymentMethodPut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/user/payment_methods/{payment_method_id}`.replace(`{${"payment_method_id"}}`, encodeURIComponent(String(requestParameters['paymentMethodId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PaymentMethodPutRequestToJSON(requestParameters['paymentMethodPutRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaymentMethodFromJSON(jsonValue));
    }

    /**
     * Update payment method values
     */
    async paymentMethodPut(requestParameters: PaymentMethodPutOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaymentMethod> {
        const response = await this.paymentMethodPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get rate update requests.
     */
    async rateUpdateGetRaw(requestParameters: RateUpdateGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RateUpdateResponse>> {
        if (requestParameters['hotelStayId'] == null) {
            throw new runtime.RequiredError(
                'hotelStayId',
                'Required parameter "hotelStayId" was null or undefined when calling rateUpdateGet().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['hotelStayId'] != null) {
            queryParameters['hotel_stay_id'] = requestParameters['hotelStayId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/rate_update`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RateUpdateResponseFromJSON(jsonValue));
    }

    /**
     * Get rate update requests.
     */
    async rateUpdateGet(requestParameters: RateUpdateGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RateUpdateResponse> {
        const response = await this.rateUpdateGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a rate update request.
     */
    async rateUpdatePostRaw(requestParameters: RateUpdatePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RateUpdateResponse>> {
        if (requestParameters['rateUpdateRequest'] == null) {
            throw new runtime.RequiredError(
                'rateUpdateRequest',
                'Required parameter "rateUpdateRequest" was null or undefined when calling rateUpdatePost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/rate_update`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RateUpdateRequestToJSON(requestParameters['rateUpdateRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RateUpdateResponseFromJSON(jsonValue));
    }

    /**
     * Create a rate update request.
     */
    async rateUpdatePost(requestParameters: RateUpdatePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RateUpdateResponse> {
        const response = await this.rateUpdatePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Read User
     */
    async userGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<User>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/user`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     * Read User
     */
    async userGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<User> {
        const response = await this.userGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Read a user\'s loyalty accounts
     */
    async userLoyaltyAccountsGetRaw(requestParameters: UserLoyaltyAccountsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<LoyaltyAccount>>> {
        const queryParameters: any = {};

        if (requestParameters['extended'] != null) {
            queryParameters['extended'] = requestParameters['extended'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/user/loyalty_accounts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(LoyaltyAccountFromJSON));
    }

    /**
     * Read a user\'s loyalty accounts
     */
    async userLoyaltyAccountsGet(requestParameters: UserLoyaltyAccountsGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<LoyaltyAccount>> {
        const response = await this.userLoyaltyAccountsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Manage gmail account - validate, disconnect, delete
     */
    async userManageGmailAccountPostRaw(requestParameters: UserManageGmailAccountPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ManageGmailResponse>> {
        if (requestParameters['manageGmailRequest'] == null) {
            throw new runtime.RequiredError(
                'manageGmailRequest',
                'Required parameter "manageGmailRequest" was null or undefined when calling userManageGmailAccountPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/user/manage_gmail_account`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ManageGmailRequestToJSON(requestParameters['manageGmailRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ManageGmailResponseFromJSON(jsonValue));
    }

    /**
     * Manage gmail account - validate, disconnect, delete
     */
    async userManageGmailAccountPost(requestParameters: UserManageGmailAccountPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ManageGmailResponse> {
        const response = await this.userManageGmailAccountPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a user\'s payment methods.
     */
    async userPaymentMethodsGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PaymentMethod>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/user/payment_methods`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PaymentMethodFromJSON));
    }

    /**
     * Get a user\'s payment methods.
     */
    async userPaymentMethodsGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PaymentMethod>> {
        const response = await this.userPaymentMethodsGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Delete a payment method.
     */
    async userPaymentMethodsPaymentMethodIdDeleteRaw(requestParameters: UserPaymentMethodsPaymentMethodIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SuccessResponse>> {
        if (requestParameters['paymentMethodId'] == null) {
            throw new runtime.RequiredError(
                'paymentMethodId',
                'Required parameter "paymentMethodId" was null or undefined when calling userPaymentMethodsPaymentMethodIdDelete().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/user/payment_methods/{payment_method_id}`.replace(`{${"payment_method_id"}}`, encodeURIComponent(String(requestParameters['paymentMethodId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SuccessResponseFromJSON(jsonValue));
    }

    /**
     * Delete a payment method.
     */
    async userPaymentMethodsPaymentMethodIdDelete(requestParameters: UserPaymentMethodsPaymentMethodIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SuccessResponse> {
        const response = await this.userPaymentMethodsPaymentMethodIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a payment method.
     */
    async userPaymentMethodsPaymentMethodIdGetRaw(requestParameters: UserPaymentMethodsPaymentMethodIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaymentMethod>> {
        if (requestParameters['paymentMethodId'] == null) {
            throw new runtime.RequiredError(
                'paymentMethodId',
                'Required parameter "paymentMethodId" was null or undefined when calling userPaymentMethodsPaymentMethodIdGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/user/payment_methods/{payment_method_id}`.replace(`{${"payment_method_id"}}`, encodeURIComponent(String(requestParameters['paymentMethodId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaymentMethodFromJSON(jsonValue));
    }

    /**
     * Get a payment method.
     */
    async userPaymentMethodsPaymentMethodIdGet(requestParameters: UserPaymentMethodsPaymentMethodIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaymentMethod> {
        const response = await this.userPaymentMethodsPaymentMethodIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a payment method.
     */
    async userPaymentMethodsPostRaw(requestParameters: UserPaymentMethodsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaymentMethod>> {
        if (requestParameters['paymentMethod'] == null) {
            throw new runtime.RequiredError(
                'paymentMethod',
                'Required parameter "paymentMethod" was null or undefined when calling userPaymentMethodsPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/user/payment_methods`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PaymentMethodToJSON(requestParameters['paymentMethod']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaymentMethodFromJSON(jsonValue));
    }

    /**
     * Create a payment method.
     */
    async userPaymentMethodsPost(requestParameters: UserPaymentMethodsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaymentMethod> {
        const response = await this.userPaymentMethodsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create user manually outside of next auth flow
     */
    async userPostRaw(requestParameters: UserPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<User>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/user`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserPostRequestToJSON(requestParameters['userPostRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     * Create user manually outside of next auth flow
     */
    async userPost(requestParameters: UserPostOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<User> {
        const response = await this.userPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update User Status
     */
    async userPutRaw(requestParameters: UserPutOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<User>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/user`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UserPutRequestToJSON(requestParameters['userPutRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     * Update User Status
     */
    async userPut(requestParameters: UserPutOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<User> {
        const response = await this.userPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a user\'s travel history.
     */
    async userTravelHistoryGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TravelHistoryResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/user/travel_history`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TravelHistoryResponseFromJSON(jsonValue));
    }

    /**
     * Get a user\'s travel history.
     */
    async userTravelHistoryGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TravelHistoryResponse> {
        const response = await this.userTravelHistoryGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get a user\'s travel profiles.
     */
    async userTravelProfilesGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TravelProfileResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/user/travel_profiles`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TravelProfileResponseFromJSON(jsonValue));
    }

    /**
     * Get a user\'s travel profiles.
     */
    async userTravelProfilesGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TravelProfileResponse> {
        const response = await this.userTravelProfilesGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Create a travel profile.
     */
    async userTravelProfilesPostRaw(requestParameters: UserTravelProfilesPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TravelProfile>> {
        if (requestParameters['travelProfile'] == null) {
            throw new runtime.RequiredError(
                'travelProfile',
                'Required parameter "travelProfile" was null or undefined when calling userTravelProfilesPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/user/travel_profiles`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TravelProfileToJSON(requestParameters['travelProfile']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TravelProfileFromJSON(jsonValue));
    }

    /**
     * Create a travel profile.
     */
    async userTravelProfilesPost(requestParameters: UserTravelProfilesPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TravelProfile> {
        const response = await this.userTravelProfilesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a travel profile.
     */
    async userTravelProfilesTravelProfileIdGetRaw(requestParameters: UserTravelProfilesTravelProfileIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TravelProfile>> {
        if (requestParameters['travelProfileId'] == null) {
            throw new runtime.RequiredError(
                'travelProfileId',
                'Required parameter "travelProfileId" was null or undefined when calling userTravelProfilesTravelProfileIdGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/user/travel_profiles/{travel_profile_id}`.replace(`{${"travel_profile_id"}}`, encodeURIComponent(String(requestParameters['travelProfileId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TravelProfileFromJSON(jsonValue));
    }

    /**
     * Get a travel profile.
     */
    async userTravelProfilesTravelProfileIdGet(requestParameters: UserTravelProfilesTravelProfileIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TravelProfile> {
        const response = await this.userTravelProfilesTravelProfileIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a travel profile.
     */
    async userTravelProfilesTravelProfileIdPutRaw(requestParameters: UserTravelProfilesTravelProfileIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TravelProfile>> {
        if (requestParameters['travelProfileId'] == null) {
            throw new runtime.RequiredError(
                'travelProfileId',
                'Required parameter "travelProfileId" was null or undefined when calling userTravelProfilesTravelProfileIdPut().'
            );
        }

        if (requestParameters['travelProfile'] == null) {
            throw new runtime.RequiredError(
                'travelProfile',
                'Required parameter "travelProfile" was null or undefined when calling userTravelProfilesTravelProfileIdPut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/user/travel_profiles/{travel_profile_id}`.replace(`{${"travel_profile_id"}}`, encodeURIComponent(String(requestParameters['travelProfileId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TravelProfileToJSON(requestParameters['travelProfile']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TravelProfileFromJSON(jsonValue));
    }

    /**
     * Update a travel profile.
     */
    async userTravelProfilesTravelProfileIdPut(requestParameters: UserTravelProfilesTravelProfileIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TravelProfile> {
        const response = await this.userTravelProfilesTravelProfileIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a user\'s trips.
     */
    async userTripsGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Trips>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/user/trips`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TripsFromJSON(jsonValue));
    }

    /**
     * Get a user\'s trips.
     */
    async userTripsGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Trips> {
        const response = await this.userTripsGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Mark a hotel trip as cancelled.
     */
    async userTripsHotelsBookingsBookingIdPutRaw(requestParameters: UserTripsHotelsBookingsBookingIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Hotel>> {
        if (requestParameters['bookingId'] == null) {
            throw new runtime.RequiredError(
                'bookingId',
                'Required parameter "bookingId" was null or undefined when calling userTripsHotelsBookingsBookingIdPut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/user/trips/hotels/bookings/{booking_id}`.replace(`{${"booking_id"}}`, encodeURIComponent(String(requestParameters['bookingId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HotelFromJSON(jsonValue));
    }

    /**
     * Mark a hotel trip as cancelled.
     */
    async userTripsHotelsBookingsBookingIdPut(requestParameters: UserTripsHotelsBookingsBookingIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Hotel> {
        const response = await this.userTripsHotelsBookingsBookingIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a trip.
     */
    async userTripsPostRaw(requestParameters: UserTripsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Trip>> {
        if (requestParameters['trip'] == null) {
            throw new runtime.RequiredError(
                'trip',
                'Required parameter "trip" was null or undefined when calling userTripsPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/user/trips`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TripToJSON(requestParameters['trip']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TripFromJSON(jsonValue));
    }

    /**
     * Create a trip.
     */
    async userTripsPost(requestParameters: UserTripsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Trip> {
        const response = await this.userTripsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Sync user trips.
     */
    async userTripsSyncPostRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Trips>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/user/trips/sync`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TripsFromJSON(jsonValue));
    }

    /**
     * Sync user trips.
     */
    async userTripsSyncPost(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Trips> {
        const response = await this.userTripsSyncPostRaw(initOverrides);
        return await response.value();
    }

    /**
     * Given a booking, apply all available credits, e.g. gives credit to the referring user for the booking made, give a 3% cashback credit to the user making the booking, etc.
     */
    async usersCreditsBookingsBookingIdPostRaw(requestParameters: UsersCreditsBookingsBookingIdPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserBookingCredits>> {
        if (requestParameters['bookingId'] == null) {
            throw new runtime.RequiredError(
                'bookingId',
                'Required parameter "bookingId" was null or undefined when calling usersCreditsBookingsBookingIdPost().'
            );
        }

        if (requestParameters['applyBookingCreditsRequest'] == null) {
            throw new runtime.RequiredError(
                'applyBookingCreditsRequest',
                'Required parameter "applyBookingCreditsRequest" was null or undefined when calling usersCreditsBookingsBookingIdPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/users/credits/bookings/{booking_id}`.replace(`{${"booking_id"}}`, encodeURIComponent(String(requestParameters['bookingId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApplyBookingCreditsRequestToJSON(requestParameters['applyBookingCreditsRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserBookingCreditsFromJSON(jsonValue));
    }

    /**
     * Given a booking, apply all available credits, e.g. gives credit to the referring user for the booking made, give a 3% cashback credit to the user making the booking, etc.
     */
    async usersCreditsBookingsBookingIdPost(requestParameters: UsersCreditsBookingsBookingIdPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserBookingCredits> {
        const response = await this.usersCreditsBookingsBookingIdPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Adjust credits by the specified amount.
     */
    async usersCreditsBookingsBookingIdPutRaw(requestParameters: UsersCreditsBookingsBookingIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserBookingCredits>> {
        if (requestParameters['bookingId'] == null) {
            throw new runtime.RequiredError(
                'bookingId',
                'Required parameter "bookingId" was null or undefined when calling usersCreditsBookingsBookingIdPut().'
            );
        }

        if (requestParameters['adjustCreditRequest'] == null) {
            throw new runtime.RequiredError(
                'adjustCreditRequest',
                'Required parameter "adjustCreditRequest" was null or undefined when calling usersCreditsBookingsBookingIdPut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/users/credits/bookings/{booking_id}`.replace(`{${"booking_id"}}`, encodeURIComponent(String(requestParameters['bookingId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AdjustCreditRequestToJSON(requestParameters['adjustCreditRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserBookingCreditsFromJSON(jsonValue));
    }

    /**
     * Adjust credits by the specified amount.
     */
    async usersCreditsBookingsBookingIdPut(requestParameters: UsersCreditsBookingsBookingIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserBookingCredits> {
        const response = await this.usersCreditsBookingsBookingIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a user\'s credits information, which can be from referrals, etc.
     */
    async usersCreditsGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserCredits>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/users/credits`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserCreditsFromJSON(jsonValue));
    }

    /**
     * Get a user\'s credits information, which can be from referrals, etc.
     */
    async usersCreditsGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserCredits> {
        const response = await this.usersCreditsGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Check if we can send an email.
     */
    async usersEmailsCanSendPostRaw(requestParameters: UsersEmailsCanSendPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CanSendEmailResponse>> {
        if (requestParameters['canSendEmailRequest'] == null) {
            throw new runtime.RequiredError(
                'canSendEmailRequest',
                'Required parameter "canSendEmailRequest" was null or undefined when calling usersEmailsCanSendPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/users/emails/can_send`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CanSendEmailRequestToJSON(requestParameters['canSendEmailRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CanSendEmailResponseFromJSON(jsonValue));
    }

    /**
     * Check if we can send an email.
     */
    async usersEmailsCanSendPost(requestParameters: UsersEmailsCanSendPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CanSendEmailResponse> {
        const response = await this.usersEmailsCanSendPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Record an email sent.
     */
    async usersEmailsPostRaw(requestParameters: UsersEmailsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Email>> {
        if (requestParameters['recordEmailSentRequest'] == null) {
            throw new runtime.RequiredError(
                'recordEmailSentRequest',
                'Required parameter "recordEmailSentRequest" was null or undefined when calling usersEmailsPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/users/emails`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RecordEmailSentRequestToJSON(requestParameters['recordEmailSentRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmailFromJSON(jsonValue));
    }

    /**
     * Record an email sent.
     */
    async usersEmailsPost(requestParameters: UsersEmailsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Email> {
        const response = await this.usersEmailsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gondola mapped.
     */
    async usersGondolaMappedYearYearGetRaw(requestParameters: UsersGondolaMappedYearYearGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GondolaMappedResponse>> {
        if (requestParameters['year'] == null) {
            throw new runtime.RequiredError(
                'year',
                'Required parameter "year" was null or undefined when calling usersGondolaMappedYearYearGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/users/gondola_mapped/year/{year}`.replace(`{${"year"}}`, encodeURIComponent(String(requestParameters['year']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GondolaMappedResponseFromJSON(jsonValue));
    }

    /**
     * Gondola mapped.
     */
    async usersGondolaMappedYearYearGet(requestParameters: UsersGondolaMappedYearYearGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GondolaMappedResponse> {
        const response = await this.usersGondolaMappedYearYearGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Initializes the user\'s referral code and optionally set that user\'s referred-by field.
     */
    async usersInvitesPostRaw(requestParameters: UsersInvitesPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Referral>> {
        if (requestParameters['referralsRequest'] == null) {
            throw new runtime.RequiredError(
                'referralsRequest',
                'Required parameter "referralsRequest" was null or undefined when calling usersInvitesPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/users/invites`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReferralsRequestToJSON(requestParameters['referralsRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferralFromJSON(jsonValue));
    }

    /**
     * Initializes the user\'s referral code and optionally set that user\'s referred-by field.
     */
    async usersInvitesPost(requestParameters: UsersInvitesPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Referral> {
        const response = await this.usersInvitesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create an MX connect-widget URL.
     */
    async usersMxConnectWidgetUrlsPostRaw(requestParameters: UsersMxConnectWidgetUrlsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MxConnectWidgetUrlResponse>> {
        if (requestParameters['mxConnectWidgetUrlRequest'] == null) {
            throw new runtime.RequiredError(
                'mxConnectWidgetUrlRequest',
                'Required parameter "mxConnectWidgetUrlRequest" was null or undefined when calling usersMxConnectWidgetUrlsPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/users/mx/connect_widget_urls`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MxConnectWidgetUrlRequestToJSON(requestParameters['mxConnectWidgetUrlRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MxConnectWidgetUrlResponseFromJSON(jsonValue));
    }

    /**
     * Create an MX connect-widget URL.
     */
    async usersMxConnectWidgetUrlsPost(requestParameters: UsersMxConnectWidgetUrlsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MxConnectWidgetUrlResponse> {
        const response = await this.usersMxConnectWidgetUrlsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete an MX reward entry.
     */
    async usersMxRewardsMxRewardIdDeleteRaw(requestParameters: UsersMxRewardsMxRewardIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['mxRewardId'] == null) {
            throw new runtime.RequiredError(
                'mxRewardId',
                'Required parameter "mxRewardId" was null or undefined when calling usersMxRewardsMxRewardIdDelete().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/users/mx_rewards/{mx_reward_id}`.replace(`{${"mx_reward_id"}}`, encodeURIComponent(String(requestParameters['mxRewardId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete an MX reward entry.
     */
    async usersMxRewardsMxRewardIdDelete(requestParameters: UsersMxRewardsMxRewardIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.usersMxRewardsMxRewardIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     * Update an MX reward entry
     */
    async usersMxRewardsMxRewardIdPutRaw(requestParameters: UsersMxRewardsMxRewardIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MxReward>> {
        if (requestParameters['mxRewardId'] == null) {
            throw new runtime.RequiredError(
                'mxRewardId',
                'Required parameter "mxRewardId" was null or undefined when calling usersMxRewardsMxRewardIdPut().'
            );
        }

        if (requestParameters['mxRewardUpdateRequest'] == null) {
            throw new runtime.RequiredError(
                'mxRewardUpdateRequest',
                'Required parameter "mxRewardUpdateRequest" was null or undefined when calling usersMxRewardsMxRewardIdPut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/users/mx_rewards/{mx_reward_id}`.replace(`{${"mx_reward_id"}}`, encodeURIComponent(String(requestParameters['mxRewardId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MxRewardUpdateRequestToJSON(requestParameters['mxRewardUpdateRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MxRewardFromJSON(jsonValue));
    }

    /**
     * Update an MX reward entry
     */
    async usersMxRewardsMxRewardIdPut(requestParameters: UsersMxRewardsMxRewardIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MxReward> {
        const response = await this.usersMxRewardsMxRewardIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all MX rewards entries.
     */
    async usersMxRewardsRewardsProgramsNameGetRaw(requestParameters: UsersMxRewardsRewardsProgramsNameGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MxRewards>> {
        if (requestParameters['name'] == null) {
            throw new runtime.RequiredError(
                'name',
                'Required parameter "name" was null or undefined when calling usersMxRewardsRewardsProgramsNameGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/users/mx_rewards/rewards_programs/{name}`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters['name']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MxRewardsFromJSON(jsonValue));
    }

    /**
     * Get all MX rewards entries.
     */
    async usersMxRewardsRewardsProgramsNameGet(requestParameters: UsersMxRewardsRewardsProgramsNameGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MxRewards> {
        const response = await this.usersMxRewardsRewardsProgramsNameGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Immediately check if a sync happened or not.
     */
    async usersMxSyncPostRaw(requestParameters: UsersMxSyncPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['mxSyncRequest'] == null) {
            throw new runtime.RequiredError(
                'mxSyncRequest',
                'Required parameter "mxSyncRequest" was null or undefined when calling usersMxSyncPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/users/mx/sync`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MxSyncRequestToJSON(requestParameters['mxSyncRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Immediately check if a sync happened or not.
     */
    async usersMxSyncPost(requestParameters: UsersMxSyncPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.usersMxSyncPostRaw(requestParameters, initOverrides);
    }

    /**
     * Unsubscribe users from emails
     */
    async usersSubscriptionsPutRaw(requestParameters: UsersSubscriptionsPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['subscriptionRequest'] == null) {
            throw new runtime.RequiredError(
                'subscriptionRequest',
                'Required parameter "subscriptionRequest" was null or undefined when calling usersSubscriptionsPut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/users/subscriptions`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SubscriptionRequestToJSON(requestParameters['subscriptionRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Unsubscribe users from emails
     */
    async usersSubscriptionsPut(requestParameters: UsersSubscriptionsPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.usersSubscriptionsPutRaw(requestParameters, initOverrides);
    }

    /**
     * Delete linked google-scoped-auth account.
     */
    async usersUserIdAccountsAccountIdDeleteRaw(requestParameters: UsersUserIdAccountsAccountIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Account>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling usersUserIdAccountsAccountIdDelete().'
            );
        }

        if (requestParameters['accountId'] == null) {
            throw new runtime.RequiredError(
                'accountId',
                'Required parameter "accountId" was null or undefined when calling usersUserIdAccountsAccountIdDelete().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/users/{user_id}/accounts/{account_id}`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters['userId']))).replace(`{${"account_id"}}`, encodeURIComponent(String(requestParameters['accountId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AccountFromJSON(jsonValue));
    }

    /**
     * Delete linked google-scoped-auth account.
     */
    async usersUserIdAccountsAccountIdDelete(requestParameters: UsersUserIdAccountsAccountIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Account> {
        const response = await this.usersUserIdAccountsAccountIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Rate a user\'s past stay.
     */
    async usersUserIdBookingsBookingIdRatePastStayPutRaw(requestParameters: UsersUserIdBookingsBookingIdRatePastStayPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling usersUserIdBookingsBookingIdRatePastStayPut().'
            );
        }

        if (requestParameters['bookingId'] == null) {
            throw new runtime.RequiredError(
                'bookingId',
                'Required parameter "bookingId" was null or undefined when calling usersUserIdBookingsBookingIdRatePastStayPut().'
            );
        }

        if (requestParameters['ratePastStayRequest'] == null) {
            throw new runtime.RequiredError(
                'ratePastStayRequest',
                'Required parameter "ratePastStayRequest" was null or undefined when calling usersUserIdBookingsBookingIdRatePastStayPut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/users/{user_id}/bookings/{booking_id}/rate_past_stay`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters['userId']))).replace(`{${"booking_id"}}`, encodeURIComponent(String(requestParameters['bookingId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RatePastStayRequestToJSON(requestParameters['ratePastStayRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Rate a user\'s past stay.
     */
    async usersUserIdBookingsBookingIdRatePastStayPut(requestParameters: UsersUserIdBookingsBookingIdRatePastStayPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.usersUserIdBookingsBookingIdRatePastStayPutRaw(requestParameters, initOverrides);
    }

    /**
     * Get a user\'s welcome actions.
     */
    async usersUserIdWelcomeActionsGetRaw(requestParameters: UsersUserIdWelcomeActionsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WelcomeActions>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling usersUserIdWelcomeActionsGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/users/{user_id}/welcome_actions`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WelcomeActionsFromJSON(jsonValue));
    }

    /**
     * Get a user\'s welcome actions.
     */
    async usersUserIdWelcomeActionsGet(requestParameters: UsersUserIdWelcomeActionsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WelcomeActions> {
        const response = await this.usersUserIdWelcomeActionsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete a welcome action when it\'s been completed.
     */
    async usersUserIdWelcomeActionsWelcomeActionIdDeleteRaw(requestParameters: UsersUserIdWelcomeActionsWelcomeActionIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling usersUserIdWelcomeActionsWelcomeActionIdDelete().'
            );
        }

        if (requestParameters['welcomeActionId'] == null) {
            throw new runtime.RequiredError(
                'welcomeActionId',
                'Required parameter "welcomeActionId" was null or undefined when calling usersUserIdWelcomeActionsWelcomeActionIdDelete().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/users/{user_id}/welcome_actions/{welcome_action_id}`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters['userId']))).replace(`{${"welcome_action_id"}}`, encodeURIComponent(String(requestParameters['welcomeActionId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a welcome action when it\'s been completed.
     */
    async usersUserIdWelcomeActionsWelcomeActionIdDelete(requestParameters: UsersUserIdWelcomeActionsWelcomeActionIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.usersUserIdWelcomeActionsWelcomeActionIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     * Read a user\'s loyalty accounts
     */
    async v2UserLoyaltyAccountsGetRaw(requestParameters: V2UserLoyaltyAccountsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoyaltyAccountsResponse>> {
        const queryParameters: any = {};

        if (requestParameters['extended'] != null) {
            queryParameters['extended'] = requestParameters['extended'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/v2/user/loyalty_accounts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoyaltyAccountsResponseFromJSON(jsonValue));
    }

    /**
     * Read a user\'s loyalty accounts
     */
    async v2UserLoyaltyAccountsGet(requestParameters: V2UserLoyaltyAccountsGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoyaltyAccountsResponse> {
        const response = await this.v2UserLoyaltyAccountsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update loyalty account.
     */
    async v2UserLoyaltyAccountsLoyaltyAccountIdPutRaw(requestParameters: V2UserLoyaltyAccountsLoyaltyAccountIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoyaltyAccount>> {
        if (requestParameters['loyaltyAccountId'] == null) {
            throw new runtime.RequiredError(
                'loyaltyAccountId',
                'Required parameter "loyaltyAccountId" was null or undefined when calling v2UserLoyaltyAccountsLoyaltyAccountIdPut().'
            );
        }

        if (requestParameters['createUpdateLoyaltyAccountRequest'] == null) {
            throw new runtime.RequiredError(
                'createUpdateLoyaltyAccountRequest',
                'Required parameter "createUpdateLoyaltyAccountRequest" was null or undefined when calling v2UserLoyaltyAccountsLoyaltyAccountIdPut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/v2/user/loyalty_accounts/{loyalty_account_id}`.replace(`{${"loyalty_account_id"}}`, encodeURIComponent(String(requestParameters['loyaltyAccountId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateLoyaltyAccountRequestToJSON(requestParameters['createUpdateLoyaltyAccountRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoyaltyAccountFromJSON(jsonValue));
    }

    /**
     * Update loyalty account.
     */
    async v2UserLoyaltyAccountsLoyaltyAccountIdPut(requestParameters: V2UserLoyaltyAccountsLoyaltyAccountIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoyaltyAccount> {
        const response = await this.v2UserLoyaltyAccountsLoyaltyAccountIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a loyalty account.
     */
    async v2UserLoyaltyAccountsPostRaw(requestParameters: V2UserLoyaltyAccountsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoyaltyAccount>> {
        if (requestParameters['createUpdateLoyaltyAccountRequest'] == null) {
            throw new runtime.RequiredError(
                'createUpdateLoyaltyAccountRequest',
                'Required parameter "createUpdateLoyaltyAccountRequest" was null or undefined when calling v2UserLoyaltyAccountsPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/v2/user/loyalty_accounts`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateLoyaltyAccountRequestToJSON(requestParameters['createUpdateLoyaltyAccountRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoyaltyAccountFromJSON(jsonValue));
    }

    /**
     * Create a loyalty account.
     */
    async v2UserLoyaltyAccountsPost(requestParameters: V2UserLoyaltyAccountsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoyaltyAccount> {
        const response = await this.v2UserLoyaltyAccountsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a user\'s trips.
     */
    async v2UserTripsGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Itineraries>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/v2/user/trips`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ItinerariesFromJSON(jsonValue));
    }

    /**
     * Get a user\'s trips.
     */
    async v2UserTripsGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Itineraries> {
        const response = await this.v2UserTripsGetRaw(initOverrides);
        return await response.value();
    }

}
