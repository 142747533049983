/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RateUpdateResponse
 */
export interface RateUpdateResponse {
    /**
     * The ID of the rebooking request.
     * @type {number}
     * @memberof RateUpdateResponse
     */
    id: number;
    /**
     * The ID of the user this rebooking request belongs to.
     * @type {string}
     * @memberof RateUpdateResponse
     */
    userId: string;
    /**
     * The ID of the hotel stay this rebooking request belongs to.
     * @type {string}
     * @memberof RateUpdateResponse
     */
    hotelStayId: string;
    /**
     * The ID of the payment this rebooking request belongs to.
     * @type {string}
     * @memberof RateUpdateResponse
     */
    paymentId: string;
    /**
     * The status of the rebooking request.
     * @type {string}
     * @memberof RateUpdateResponse
     */
    status: RateUpdateResponseStatusEnum;
    /**
     * The timestamp of when the rebooking request was created.
     * @type {Date}
     * @memberof RateUpdateResponse
     */
    createdAt?: Date;
    /**
     * The timestamp of when the rebooking request was last updated.
     * @type {Date}
     * @memberof RateUpdateResponse
     */
    updatedAt?: Date;
}


/**
 * @export
 */
export const RateUpdateResponseStatusEnum = {
    Pending: 'PENDING',
    Completed: 'COMPLETED',
    Failed: 'FAILED'
} as const;
export type RateUpdateResponseStatusEnum = typeof RateUpdateResponseStatusEnum[keyof typeof RateUpdateResponseStatusEnum];


/**
 * Check if a given object implements the RateUpdateResponse interface.
 */
export function instanceOfRateUpdateResponse(value: object): value is RateUpdateResponse {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('userId' in value) || value['userId'] === undefined) return false;
    if (!('hotelStayId' in value) || value['hotelStayId'] === undefined) return false;
    if (!('paymentId' in value) || value['paymentId'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    return true;
}

export function RateUpdateResponseFromJSON(json: any): RateUpdateResponse {
    return RateUpdateResponseFromJSONTyped(json, false);
}

export function RateUpdateResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RateUpdateResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'userId': json['user_id'],
        'hotelStayId': json['hotel_stay_id'],
        'paymentId': json['payment_id'],
        'status': json['status'],
        'createdAt': json['created_at'] == null ? undefined : (new Date(json['created_at'])),
        'updatedAt': json['updated_at'] == null ? undefined : (new Date(json['updated_at'])),
    };
}

export function RateUpdateResponseToJSON(value?: RateUpdateResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'user_id': value['userId'],
        'hotel_stay_id': value['hotelStayId'],
        'payment_id': value['paymentId'],
        'status': value['status'],
        'created_at': value['createdAt'] == null ? undefined : ((value['createdAt']).toISOString()),
        'updated_at': value['updatedAt'] == null ? undefined : ((value['updatedAt']).toISOString()),
    };
}

